import React, { useState } from 'react';
import { H2, Heading, InlineTextButton, Reviews } from '../../components';
import { FormattedMessage } from 'react-intl';

import css from './ListingPage.module.css';
import classNames from 'classnames';

const SectionReviews = props => {
  const { defaultReviews, externalReviews, fetchReviewsError } = props;
  const [showExternalReviews, setShowExternalReviews] = useState(true);

  return (
    <div className={classNames(css.field, css.container)}>
      <Heading as="h2" rootClassName={css.subtitle}>
        <FormattedMessage id="ListingPage.reviewsTitle" />
      </Heading>
      <div className={css.cardHeader}>
        <InlineTextButton
          className={classNames(css.tab, { [css.activeTab]: showExternalReviews })}
          onClick={() => setShowExternalReviews(true)}
          type="button"
        >
          <FormattedMessage
            id="ListingPage.externalReviewsTitle"
            values={{ count: externalReviews?.length || 0 }}
          />
        </InlineTextButton>
        <InlineTextButton
          className={classNames(css.tab, { [css.activeTab]: !showExternalReviews })}
          onClick={() => setShowExternalReviews(false)}
          type="button"
        >
          <FormattedMessage
            id="ListingPage.defaultReviewsTitle"
            values={{ count: defaultReviews?.length || 0 }}
          />
        </InlineTextButton>
      </div>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews reviews={showExternalReviews ? externalReviews : defaultReviews} />
    </div>
  );
};

export default SectionReviews;
