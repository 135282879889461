import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal, SecondaryButton } from '../../components';
import InquiryForm from './InquiryForm/InquiryForm';

import css from './ListingPage.module.css';
import classNames from 'classnames';

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    onContactUser,
    isInquiryModalOpen,
    onCloseInquiryModal,
    sendInquiryError,
    sendInquiryInProgress,
    onSubmitInquiry,
    onManageDisableScrolling,
    contactArtistDisabled,
    contactArtistDisabledMessage,
  } = props;

  if (!listing.author) {
    return null;
  }

  return (
    <div
      id="author"
      className={classNames(css.sectionAuthor, { [css.disabled]: contactArtistDisabled })}
    >
      <p className={css.contactAuthor}>
        <FormattedMessage
          id={!contactArtistDisabled ? 'ListingPage.contactArtist' : contactArtistDisabledMessage}
        />
        <SecondaryButton
          className={css.contactBtn}
          rootClassName={css.contact}
          onClick={() => onContactUser(listing.author)}
          enforcePagePreloadFor="SignupPage"
          disabled={contactArtistDisabled}
        >
          <FormattedMessage id="ListingPage.contactArtistBtn" />
        </SecondaryButton>
      </p>

      <Modal
        id="ListingPage.inquiry"
        contentClassName={css.inquiryModalContent}
        isOpen={isInquiryModalOpen}
        onClose={onCloseInquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InquiryForm
          className={css.inquiryForm}
          submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={title}
          sendInquiryError={sendInquiryError}
          onSubmit={onSubmitInquiry}
          inProgress={sendInquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionAuthorMaybe;
