import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';

const LISTING_PAGE_STORAGE_KEY = 'ListingPage';

// Stores given bookinData
export const storeData = (orderData, listingId) => {
  if (typeof window !== 'undefined' && window && window.sessionStorage && orderData) {
    const data = {
      orderData,
      listingId,
      storedAt: new Date(),
    };

    const replacer = function(k, v) {
      if (this[k] instanceof Date) {
        return { date: v, _serializedType: 'SerializableDate' };
      }
      if (this[k] instanceof Decimal) {
        return { decimal: v, _serializedType: 'SerializableDecimal' };
      }
      return sdkTypes.replacer(k, v);
    };

    const storableData = JSON.stringify(data, replacer);

    window.sessionStorage.setItem(LISTING_PAGE_STORAGE_KEY, storableData);
  }
};

// Get stored data
export const storedData = () => {
  if (typeof window !== 'undefined' && window && window.sessionStorage) {
    const checkoutPageData = window.sessionStorage.getItem(LISTING_PAGE_STORAGE_KEY);

    const reviver = (k, v) => {
      if (v && typeof v === 'object' && v._serializedType === 'SerializableDate') {
        // Dates are expected to be stored as:
        // { date: new Date(), _serializedType: 'SerializableDate' }
        return new Date(v.date);
      } else if (v && typeof v === 'object' && v._serializedType === 'SerializableDecimal') {
        // Decimals are expected to be stored as:
        // { decimal: v, _serializedType: 'SerializableDecimal' }
        return new Decimal(v.decimal);
      }
      return sdkTypes.reviver(k, v);
    };

    const data = checkoutPageData ? JSON.parse(checkoutPageData, reviver) : {};

    return data;
  }
  return {};
};

export const removeStoredData = () => {
  if (typeof window !== 'undefined' && window && window.sessionStorage) {
    window.sessionStorage.removeItem(LISTING_PAGE_STORAGE_KEY);
  }
};
